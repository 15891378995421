import React from 'react';
import NavBarComponent from "../components/NavBarComponent";
import {Col, Image, Row} from "react-bootstrap";
import img from '../img/profunion/image001.png'

function ProfUnion(props) {
    return (
        <>
            <NavBarComponent/>
            <Row style={{ textAlign: "center" }}>
                <Col md={12}>
                    <Image src={img} alt="img" />
                </Col>
            </Row>
            <Row style={{ textAlign: "center" }}>
                <Col md={12}>
                   <h2>Деятельность профсоюза</h2>
                </Col>
            </Row>
            <Row style={{ textAlign: "center" }}>
                <Col  md={3}></Col>
                <Col md={6}>
                    <p>

                        Руководящий орган — профсоюзный комитет, в который избрано 9 членов.
                        Профком ОАО «Управление СтройМеханизации» в своей работе руководствуется законодательством РБ, Уставом профсоюза и Положением о первичной  профсоюзной организацией.    Главная задача профсоюза – защита профессиональных, трудовых, социально-экономических прав и законных интересов членов профсоюза.
                        Залогом успешной работы коллектива является его сплоченность. Немаловажную роль в этом играют общественные организации. Одно из приоритетных направлений руководства коллектива и профсоюзной организации – создание нормальных трудовых и социально-экономических условий для работников ОАО «Управление СтройМеханизации». Профсоюзный комитет ОАО «Управление СтройМеханизации» принимает самое деятельное участие в культурно-воспитательной, экскурсионной, профилактическо-оздоровительной, спортивной жизни предприятия.
                        Первичная профсоюзная организация ОАО «Управление СтройМеханизации» объединяет  в своих рядах более 97% членов профсоюза от числа работающих.
                        Одним из важнейших направлений работы профсоюзной организаций является подготовка, принятие и контроль за выполнением коллективного договора. Коллективный договор содержит ряд дополнительных льгот и гарантий. Выплачивается материальная помощь: на оздоровление работников, к юбилейным датам, в связи с бракосочетанием, рождением ребенка, в связи с продолжительной болезнью, на ритуальные услуги.

                        Профсоюз – это не только материальная помощь, Профсоюз (профессиональный союз) — это общественная организация, объединяющая работников для решения насущных и важных проблем.
                        Мы – объединяем! Мы - вместе!



                    </p>
                </Col>
                <Col  md={3}></Col>
            </Row>

        </>
    );
}

export default ProfUnion;