import React from 'react';
import NavBarComponent from "../components/NavBarComponent";
import {Button, Col, Image, Row} from "react-bootstrap";
import img from '../img/coruption/coruption.jpg'
import {useNavigate} from "react-router-dom";

function Corrumpere(props) {
    const  navigate=useNavigate()
    function handleCor() {
        navigate("/coruptionAppend")
    }

    return (
        <>
            <NavBarComponent/>
            <Row>
                <Col md={8}>
                    <Row>
                        <Col md={2}></Col>
                        <Col md={8}>
                            <br/><br/>
                            <h1>БОРЬБА С КОРРУПЦИЕЙ – ВАЖНЕЙШАЯ ЗАДАЧА ГОСУДАРСТВА И ОБЩЕСТВА</h1>
                        </Col>
                        <Col md={2}></Col>

                    </Row>
                    <Row>
                        <Col md={2}></Col>
                        <Col md={8}>
                            <br/><br/>
                            <Image src={img} height={100} width={400}></Image>
                        </Col>
                        <Col md={2}></Col>

                    </Row>
                    <Row>
                        <Col md={2}></Col>
                        <Col md={8}>
                            <br/><br/>
                            <div>
                                На разных этапах общественного развития одним из самых пагубных явлений выступает
                                коррупция (от лат. corrumpere – портить). Злоупотребление властью для получения выгоды в
                                личных целях сопровождает человечество с древнейших времен. Несмотря на то, что история
                                знает немало примеров борьбы с этим злом (порой крайне жестоких), к сожалению, оно не
                                изжито до сих пор. В XXI веке коррупция остается в числе актуальных проблем, присущих
                                всем без исключения странам.
                            </div>
                            <br/>
                            <div>
                                Коррупционные правонарушения наносят колоссальный материальный ущерб современным
                                государствам. Ежегодно в мире на взятки тратится 1 трлн. долларов США. Эти данные
                                приведены в прошлогоднем исследовании ООН, подготовленном к Международному дню борьбы с
                                коррупцией (отмечается 9 декабря). В результате коррупции государства недополучают
                                ежегодно 2,6 трлн. долларов США – сумму, эквивалентную 5% мирового ВВП. В развивающихся
                                странах потери, связанные с коррупцией, превосходят в 10 раз суммы, выделяющиеся в
                                качестве официальной помощи. В Европейской комиссии ЕС утверждают, что коррупция каждый
                                год приносит ущерб евроэкономике в размере 120 млрд. евро. А по оценкам Европейского
                                бюро по борьбе с мошенничеством (англ. – OLAF), страны Европейского союза ежегодно
                                теряют от коррупции 323 млрд. евро
                            </div>
                            <br/>
                            <div><b>В Беларуси созданы и на практике реализованы механизмы государственной
                                антикоррупционной политики, доказавшие свою эффективность.</b></div>
                            <br/>
                            <div style={{textAlign: "center"}}><b> <u>Негативное влияние коррупции на
                                социально-экономические процессы</u> </b></div>
                            <br/>
                            <div>
                                В белорусском законодательстве <b>коррупция</b> определена как <b>умышленное
                                использование служебного положения в целях противоправного получения имущества или
                                другой выгоды, а также подкуп должностного или приравненного к нему лица</b> .
                            </div>
                            <br/>
                            <div>Основные <b>негативные экономические последствия</b> от коррупции заключаются в
                                следующем:
                            </div>
                            <br/>
                            <div>
                                <ul>
                                    <li>
                                        расширяется теневая экономика (а на ее основе криминальный бизнес), что приводит
                                        к уменьшению налоговых поступлений и ослаблению бюджета;
                                    </li>
                                    <li>
                                        нарушается конкурентное регулирование рынка, поскольку «конкурентоспособным» становится тот, кто получил преимущества незаконно;
                                    </li>
                                    <li>
                                        неэффективно используются бюджетные средства при распределении государственных заказов и кредитов;
                                    </li>
                                    <li>
                                        в случае нарушений в ходе приватизации, а также искусственных банкротств замедляется появление эффективных частных производств и собственников;
                                    </li>
                                    <li>
                                        повышаются себестоимость производства продукции и товаров, отпускные (розничные) цены за счет коррупционных «накладных расходов», растет инфляция;
                                    </li>
                                    <li>
                                        ухудшается инвестиционный климат. У агентов рынка появляется неверие в способность властей устанавливать, контролировать и соблюдать честные правила рыночных отношений.
                                    </li>
                                </ul>
                            </div>
                            <br/>
                            <div style={{textAlign: "center"}}><b> <u>Законодательство Республики Беларусь о борьбе с коррупцией</u> </b></div>
                            <br/>
                            <br/>
                            <div>Правовые основы антикоррупционной деятельности в нашей стране содержатся в <b>Конституции Республики Беларусь</b>, регламентирующей наиболее важные общественные отношения.</div>
                            <br/>
                            <br/>
                            <div>Кроме того, наша страна является активной участницей ряда конвенций ООН и Совета Европы в области борьбы с коррупцией и организованной преступностью. Выполняя соответствующие международные обязательства, Республика Беларусь в рамках своей правовой системы последовательно принимает законодательные, административные и иные антикоррупционные меры.</div>
                            <br/>
                            <br/>
                            <div>На реализацию норм Конституции и международно-правовых актов направлен <a href="https://pravo.by/upload/docs/op/H11500305_1437598800.pdf"> Закон Республики Беларусь от 15 июля 2015 года № 305-З «О борьбе с коррупцией».</a></div>
                            <br/>
                            <br/>
                            <div >Антикоррупционное законодательство Республики Беларусь состоит также из иных нормативных правовых актов, направленных на пресечение, профилактику, предупреждение коррупции и борьбу с ней.</div>
                        </Col>
                        <Col md={2}></Col>

                    </Row>

                </Col>
                <Col md={4}>
                    <br/>
                    <h1>Информация</h1>
                    <Button variant="link" className="me-3" onClick={handleCor} ><h4>План организационнно-профилактических мероприятий по противодействию корупции на 2024 roд</h4></Button>
                </Col>
            </Row>


        </>
    );
}

export default Corrumpere;